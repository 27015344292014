import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ExternalButtonLink } from "components/buttons"

const StyledFindOpportunity = styled.section`
max-width: 1150px;
margin: 0 auto;
padding: 68px 0;
  text-align: center;

  ${breakpoint.medium`
    padding: 124px 0;
  `}

  ${Container} {
    max-width: 1150px;
    margin: 0 auto;
     }


  h2 {
    padding-bottom: 2rem;    
  }

  p {
    max-width: 827px;
    margin: 0 auto 32px auto;

    ${breakpoint.medium`
      margin-bottom: 50px;
    `}
  }

  a {
    width: auto;

    &:hover {
      background-color: ${colors.charcoal};
    }
  }
  
  	.thumbnails_row {
      display: flex;
      flex-wrap: wrap;
      max-width: 1100px;
      justify-content: center;
      padding: 0;

		.image_column { 
			padding-bottom: 30px;
			margin: 10px;
		}

		.thumbnail_image { 
			width: 125px;
		} 
	}

`

const FindOpportunity = () => {
  return (
  <StyledFindOpportunity>
    <Container data-aos="fade-up" data-aos-duration="'2000">
		<div className="thumbnails_row">
			<div className="image_column">
        <StaticImage
          src="../../../assets/images/careers/lightbulb-orange.png"
          width={200}
          alt="Opportunity at Visby"
          className="thumbnail_image"
        />
			</div>
		</div>
    <div className="title">
      <h2>Find the right opportunity at Visby</h2>
      <ExternalButtonLink href="https://boards.greenhouse.io/visbymedical" target="_blank" rel="noopener noreferrer" backgroundcolor="primary">
      See Job Openings
      </ExternalButtonLink>
    </div>
    </Container>
  </StyledFindOpportunity>
  )
}

export default FindOpportunity

